<template>
  <div id="manager" class="manager">
    <div v-if="loadingStatus" class="match-loading">
      <div class="logo">
        <div class="icon">
          <img src="@/assets/cat@2x.png" class="img">
        </div>
        <div class="title">加载中…</div>
      </div>
      <div class="process">
        <van-progress :percentage="progress" stroke-width="16" />
      </div>
      <div class="notice">
        <div class="title">温馨提示</div>
        <div class="cont">
          <div class="p">系统会将您的贷款需求发布到经理人端，选</div>
          <div class="p">定合适的经理人后，联系可上门服务哦~</div>
        </div>
      </div>
    </div>
    <div v-else class="manager-match">
      <div class="list">
        <template v-if="matchList.length > 0">
          <div v-for="item in matchList" :key="item.id" class="item">
            <div class="avatar">
              <img :src="item.headImgUrl" class="img">
            </div>
            <div class="cont">
              <div class="head">
                <div class="name">{{ item.realName }}</div>
                <div class="sex">
                  <img v-if="item.sex == 1" src="@/assets/male@2x.png" class="icon">
                  <img v-else src="@/assets/female@2x.png" class="icon">
                  <span class="age">{{ item.age }}岁</span>
                </div>
              </div>
              <div class="des">
                <div class="p">已成交订单数：{{ item.orderCount }}</div>
              </div>
              <div class="detail" @click="goDetail(item)">详情</div>
            </div>
            <div class="per">
              <div class="dist">{{ (item.distance/1000).toFixed(2) }}km</div>
              <div class="btn" @click="goSelect(item)">立即联系</div>
            </div>
            <div v-if="item.chatState" class="status">沟通过</div>
          </div>
          <div class="bottom">
            <div class="title">系统为您推荐了{{ matchList.length }}位经理人</div>
            <div class="line" />
          </div>
        </template>
        <template v-else>
          <div class="none">
            <div class="icon">
              <img :src="emptyIcon" class="img">
            </div>
            <div class="title">很遗憾！附近没有符合您贷款需求的经理人建议修改您的贷款需求~</div>
            <div class="btn" @click="goChange">去修改</div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { sayManagers } from '@/api/user'
import { matchConfirm } from '@/api/product'
import { getOssUrl } from '@/utils/oss'
import { getLocalUserInfo } from '@/utils/auth'
import emptyIcon from '@/assets/empty5@2x.png'
export default {
  name: 'ManagerMatch',
  data() {
    return {
      params: {
        orderId: '',
        bistanceCriteria: true
      },
      loadingStatus: false,
      progress: 0,
      timer: null,
      matchList: [],
      city: null,
      keep: false, // 缓存状态
      emptyIcon
    }
  },
  async mounted() {
    if (!this.keep) {
      await this.goReset()
    }
  },
  beforeRouteLeave(to, from, next) {
    console.log(document.getElementById('manager').scrollTop, '2222')
    if (to.name === 'managerDetail') {
      this.scrollY = document.getElementById('manager').scrollTop
    }
    next()
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name !== 'managerDetail') {
        vm.scrollY = 0
        vm.goReset()
      }
    })
  },
  activated() {
    document.getElementById('manager').scrollTop = this.scrollY
  },
  methods: {
    goDetail(item) {
      this.$router.push({ name: 'managerDetail', params: { order: this.params.orderId, userId: item.id, your: item.eaUserName }})
    },
    startProgress() {
      this.timer = setInterval(() => {
        const progress = this.progress + 18 > 99 ? 100 : this.progress + 18
        console.log(progress)
        if (progress >= 99) {
          clearInterval(this.timer)
        }
        this.progress = progress
      }, 500)
    },
    goSelect(item) {
      this.$dialog.confirm({
        title: '提示',
        message: '选定经理人之后不可更换，确定选择该业务员为经理人？',
        confirmButtonColor: '#FF7700'
      })
        .then(async() => {
          //
          const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
          await matchConfirm({ orderId: this.params.orderId, salesId: item.id })
          loading.clear()
          this.$store.dispatch('user/setSelectMsgStatus', true)
          this.$router.replace({ name: 'chatRoom', params: { your: item.eaUserName }})
        })
        .catch(() => {
          // on cancel
        })
    },
    goChange() {
      this.$router.replace({ name: 'loanInfo', query: { orderId: this.params.orderId }})
    },
    async goReset() {
      this.loadingStatus = true
      const { city } = getLocalUserInfo()
      this.city = city
      const { orderId } = this.$route.params
      this.params.orderId = orderId
      await this.getMatchList()
    },
    async getMatchList() {
      this.startProgress()
      try {
        const params = { ...this.params, ...this.city }
        const res = await sayManagers(params)
        const list = res.datas || []
        for (let i = 0; i < list.length; i++) {
          list[i].headImgUrl = await getOssUrl(list[i].headImgUrl)
        }
        this.progress = 100
        this.matchList = list
        setTimeout(() => {
          this.loadingStatus = false
        }, 1000)
      } catch {
        this.progress = 0
        clearInterval(this.timer)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.manager {
  min-height: 100%;
  background: #fcfcfc;
}
.match-loading {
  .logo {
    padding-top: 150px;
    text-align: center;
    .icon {
      .img {
        width: 92px;
        height: 75px;
      }
    }
    .title {
      padding-top: 12px;
      font-size: 12px;
      color: #FF7700;
    }
  }
  .process::v-deep {
    padding: 10px 23px;
    .van-progress {
      background: #e5e5e5;
      border-radius: 100px;
    }
    .van-progress__portion {
      background: #FF7700;
    }
    .van-progress__pivot {
      margin-left: -20px;
      background: none;
    }
  }
  .notice {
    padding-top: 25px;
    text-align: center;
    font-size: 14px;
    .title {
      color: #2E3135;
    }
    .cont {
      padding-top: 10px;
      .p {
        line-height: 20px;
        color: #999999;
      }
    }
  }
}

.manager-match {
  padding: 10px;
  .list {
    .item {
      position: relative;
      display: flex;
      padding: 16px;
      margin-bottom: 10px;
      background: #FFFFFF;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06);
      border-radius: 12px;
      &:last-child {
        border: 0;
      }
      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        .img {
          width: 40px;
          height: 40px;
          border-radius: 50px;
        }
      }
      .cont {
        flex: 1;
        padding-left: 10px;
        .head {
          display: flex;
          align-items: center;
          .name {
            padding-right: 8px;
            font-size: 16px;
          }
          .sex {
            display: flex;
            align-items: center;
            padding: 3px 5px;
            border-radius: 4px;
            background: #f3f3f3;
            .icon {
              width: 15px;
              height: 15px;
            }
            .age {
              padding-left: 5px;
              font-size: 12px;
              color: #666666;
            }
          }
        }
        .des {
          font-size: 12px;
          color: #999999;
          .p {
            padding-top: 5px;
          }
        }
        .detail {
          padding-top: 12px;
          font-size: 12px;
          color: #2697FF;
        }
      }
      .per {
        padding-top: 15px;
        text-align: center;
        .dist {
          padding-bottom: 10px;
          font-size: 14px;
          color: #32465A;
        }
        .btn {
          width: 70px;
          padding: 7px 0;
          font-size: 12px;
          text-align: center;
          color: #ffffff;
          border-radius: 50px;
          background: linear-gradient(136deg, #ffb367 0%, #ff7700 100%);
          &.disabled {
            background: #e6e6e6;
          }
        }
      }
      .status {
        position: absolute;
        right: 0;
        top: 0;
        width: 50px;
        height: 20px;
        line-height: 20px;
        font-size: 10px;
        color: #FF7700;
        text-align: center;
        border-radius:0 12px 0 4px;
        background: #ffebd9;
      }
    }
    .bottom {
      position: relative;
      display: flex;
      justify-content: center;
      height: 20px;
      line-height: 20px;
      margin-top: 20px;
      text-align: center;
      .title {
        flex: none;
        width: 200px;
        font-size: 12px;
        color: #CCCCCC;
        background: #fcfcfc;
        z-index: 999;
      }
      .line {
        position: absolute;
        top: 10px;
        left: 10px;
        width: 335px;
        height: 1px;
        background: #E6E6E6;
      }
    }
  }
}

.none {
  padding-top: 190px;
  .title {
    padding: 16px 50px;
    line-height: 20px;
    text-align: center;
    color: #CCCCCC;
  }
  .icon {
    text-align: center;
    .img {
      width: 195px;
      height: 160px;
    }
  }
  .btn {
    width: 70px;
    height: 30px;
    margin: 0 auto;
    line-height: 30px;
    font-size: 12px;
    color: #FF7700;
    text-align: center;
    border: 1px solid #FF7700;
    border-radius: 100px;
  }
}

</style>
